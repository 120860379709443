<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="image"
      width="100%"
      height="100%"
    />
    <div
      v-else-if="!routeCoordinates.length > 0"
      class="d-flex justify-center align-center text-muted"
      style="height: 365px"
    >
      The map is available only for 'Today' or 'Yesterday'.
    </div>
    <l-map
      v-else
      class="VueLeafMap"
      :zoom="zoom"
      :center="MapCenter"
      :options="mapOptions"
      :bounds="bounds"
      style="height: 365px"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-control-fullscreen
        position="topright"
        :options="{
          title: { false: 'Full Screen', true: 'Dashboard View' },
        }"
      />
      <template v-for="(point, i) in routeCoordinates">
        <l-marker
          v-if="i == 0 || !routeCoordinates[i + 1]"
          :key="`${point[0]}-${point[1]}-${i}`"
          :lat-lng="point"
        >
          <l-icon v-if="!routeCoordinates[i + 1]">
            <VehicleMapMarkers
              :name="vehicleModelType"
              :status="status"
            />
          </l-icon>
        </l-marker>
      </template>

      <!--  -->
      <l-polyline
        :lat-lngs="routeCoordinates"
        :color="'green'"
      />
    </l-map>
  </div>
</template>
<script>
import { latLngBounds } from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon, LPolyline } from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPolyline,
    LControlFullscreen,
    VehicleMapMarkers: () => import("@/components/base/VehicleMapMarkers.vue"),
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    vehicleModelType: {
      type: String,
      default() {
        return "";
      },
    },
    status: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 13,
      mapOptions: {
        zoomSnap: 0.5,
      },
    };
  },
  computed: {
    routeCoordinates() {
      const arr = this.items.map((r) => [r.latitude, r.longitude]);
      return arr;
    },
    mapMarkers() {
      const len = this.routeCoordinates.length - 1;
      const arr = [];
      arr.push(this.routeCoordinates[0]);
      arr.push(this.routeCoordinates[len]);
      return arr;
    },
    MapCenter() {
      return this.routeCoordinates[0] ?? [24.8785, 67.0641];
    },
    bounds() {
      return latLngBounds(this.routeCoordinates);
    },
    // point() {
    //   let arr = [];
    //   if (this.items.length > 0) {
    //     // arr.push([this.items[0].latitude, this.items[0].longitude]);
    //     // const len = this.items.length - 1;
    //     // arr.push([this.items[len].latitude, this.items[len].longitude]);
    //     arr = this.items.map((r) => [latLng(r.latitude, r.longitude)]);
    //   }
    //   // const { latitude, longitude } = {};
    //   return arr;
    // },
  },
};
</script>
